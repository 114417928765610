import { showErrorToast } from "components/toast/showToast";
import { useOwner } from "hooks/api"

interface Props {
  ownerId: string;
  companyId: string | undefined;
}

export const OwnershipSSN = ({ ownerId, companyId }: Props) => {

  const { owner, isLoading, isError } = useOwner(ownerId, companyId, true);

  if (isLoading) {
    return (
      <span>Loading...</span>
    )
  }

  if (isError) {
    showErrorToast('Failed to fetch owner ssn')
    return (
        <span>Error fetching owner ssn.</span>
    )
  }

  return (
    <>
      ***-**-{owner?.ssn?.slice(5)}
    </>
  )
}
