import {
  HurdlrExpenseTransactionsFilters,
  HurdlrIncomeTransactionsFilters,
  HurdlrIncomeAPITransactions,
  HurdlrExpenseAPITransactions,
  ExpenseTransactionUpdateData,
  IncomeTransactionUpdateData,
  PostMutateVariables,
} from 'hooks/api/useHurdlrTransactions';
import queryString from 'query-string';
import { api } from './axios';

export interface IHurdlrIncomeTransactionItem {
  id: number;
  status: string;
  type: string;
  date: string;
  amount: number;
  state: string;
  salesTaxAmount: number | null;
  description: string | null;
  businessId: number;
  clientId: number;
  clientName: string;
  invoiceId: number | null;
  apiName: string;
  apiPaymentId: string;
  apiAccountId: string | null;
  apiAccountNo: string;
  apiAccountName: string;
  apiInstitutionId: string | null;
  plaidItemAccountId: number;
  bankAccountId: number;
  bankDescription: string | null;
  currencyType: string;
  revenueAccountId: number;
  revenueAccountNo: string;
  revenueAccountName: string;
  customData: {
    note: string | null;
  };
  noteCount: number;
  adminUnread: boolean;
  customerUnread: boolean;
}

export interface IHurdlrExpenseTransactionItem {
  id: number;
  status: string;
  type: string;
  date: string;
  amount: number;
  state: string;
  salesTaxAmount: number | null;
  description: string | null;
  vendorId: number;
  vendorName: string;
  businessId: number;
  clientId: number;
  clientName: string;
  categoryId: number;
  categoryName: string;
  categoryAccountNo: string;
  categoryAccountName: string;
  personalCategoryID: number | null;
  personalCategoryName: string | null;
  invoiceId: number | null;
  confidence: string | null;
  frequency: string;
  recurringParentId: number | null;
  isParent: boolean;
  apiName: string;
  apiExpenseId: string;
  plaidCategoryId: number | null;
  apiAccountId: string | null;
  apiAccountNo: string | null;
  apiAccountName: string | null;
  apiInstitutionId: string | null;
  plaidItemAccountId: number | null;
  bankAccountId: number | null;
  bankDescription: string;
  customData: {
    note: string | null;
  };
  childExpenses: ChildExpense[] | null;
  noteCount: number;
  adminUnread: boolean;
  customerUnread: boolean;
}

export interface ChildExpense {
  id: number;
  status: string;
  type: string;
  amount: number;
  businessId: number;
  clientId: number;
  categoryId: number;
  personalCategoryId: number;
  invoiceId: number;
  description: string;
  salesTaxAmount: number;
  state: string;
}

export interface IHurdlrIncomeCategoryItem {
  id: number;
  status: string;
  accountNo: string;
  parentAccountNo: string;
  type: string;
  name: string;
  level: number;
  hasChilds: boolean;
  taxCategory: string;
  taxCategoryName: string;
  assetType: string;
  liabilityType: string;
  equityType: string;
  expenseType: string;
  incomeType: string;
  systemGenerated: boolean;
}

export interface IHurdlrExpenseCategoryItem {
  id: number;
  name: string;
  precedence: number;
  TaxCategory: TaxCategory | null;
  hasChilds: boolean;
  parentCategoryID: number;
  status: string;
  systemGenerated: boolean;
  customData: Object | null;
  glAccountNo: string;
  glAccountName: string;
  accountId: number;
}

interface TaxCategory {
  id: number;
  name: string;
  displayName: string;
  form1040SchedCLineNumber: string;
  form1040SchedCDisplayName: string;
  t2125LineNumber: string;
  t2125DisplayName: string;
  form1065LineNumber: string;
  form1065DisplayName: string;
  form1120SLineNumber: string;
  form1120SDisplayName: string;
}

export interface HurdlrVendor {
  id: number;
  name: string;
  status: string;
  defaultCategoryId: number;
  defaultBusinessId: number;
  defaultClientId: number;
}

export const getHurdlrExpenseTransactions = async (
  accountID: string,
  params: Partial<HurdlrExpenseTransactionsFilters>,
): Promise<HurdlrExpenseAPITransactions> => {
  const { data } = await api.get<HurdlrExpenseAPITransactions>(
    `/hurdlr/transactions/${accountID}/expense?${queryString.stringify(
      params,
    )}`,
  );
  return data;
};

export const getHurdlrIncomeTransactions = async (
  accountID: string,
  params: Partial<HurdlrIncomeTransactionsFilters>,
): Promise<HurdlrIncomeAPITransactions> => {
  const { data } = await api.get<HurdlrIncomeAPITransactions>(
    `/hurdlr/transactions/${accountID}/revenue?${queryString.stringify(
      params,
    )}`,
  );
  return data;
};

export const updateExpenseTransaction = async ({
  accountId,
  transactionId,
  categoryId,
  type,
}: ExpenseTransactionUpdateData): Promise<void> => {
  await api.patch(`hurdlr/transactions/${accountId}/expense-category`, {
    id: transactionId,
    categoryId,
    type,
  });
};

export const updateIncomeTransaction = async ({
  accountId,
  transactionId,
  revenueAccountId,
  type,
}: IncomeTransactionUpdateData): Promise<void> => {
  await api.patch(`hurdlr/transactions/${accountId}/revenue-account`, {
    id: transactionId,
    revenueAccountId,
    type,
  });
};

export const getExpenseCategories = async (
  accountID: string,
): Promise<IHurdlrExpenseCategoryItem[]> => {
  const { data } = await api.get<IHurdlrExpenseCategoryItem[]>(
    `/hurdlr/expense-categories/${accountID}`,
  );
  return data;
};

export const getIncomeCategories = async (
  accountID: string,
): Promise<IHurdlrIncomeCategoryItem[]> => {
  const { data } = await api.get<IHurdlrIncomeCategoryItem[]>(
    `/hurdlr/gl-accounts/${accountID}`,
  );
  return data;
};

export const getTransactionVendors = async (
  accountID: string,
): Promise<HurdlrVendor[]> => {
  const { data } = await api.get<HurdlrVendor[]>(
    `/hurdlr/transactions/${accountID}/vendors`,
  );
  return data;
};

export const createTransactionVendor = async (
  accountID: string,
  vendorName: string,
): Promise<{ id: string; name: string }> => {
  const { data } = await api.post<
    { name: string },
    { data: { id: string; name: string } }
  >(`/hurdlr/transactions/${accountID}/vendors`, {
    name: vendorName,
  });
  return data;
};

export const createHurdlrExpenseTransaction = async ({
  accountId,
  formTransactionData,
}: PostMutateVariables) => {
  await api.post<FormData>(
    `/hurdlr/transactions/${accountId}/expense`,
    formTransactionData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 120000,
    },
  );
};

export const getHurdlrUnknownTransactionCount = async (
  accountID: string,
  year: number,
): Promise<{ count: number }> => {
  const { data } = await api.get<{ count: number }>(
    `/hurdlr/transactions/${accountID}/count-unknown`,
    {
      params: {
        year,
      },
    },
  );
  return data;
};

export const updateHurdlrExpenseTransactionNote = async (
  accountId: string,
  transactionId: number,
  note: string,
) => {
  await api.patch(`hurdlr/transactions/${accountId}/expense-note`, {
    id: transactionId,
    note,
  });
};

export const updateHurdlrIncomeTransactionNote = async (
  accountId: string,
  transactionId: number,
  note: string,
) => {
  await api.patch(`hurdlr/transactions/${accountId}/revenue-note`, {
    id: transactionId,
    note,
  });
};

export const markExpenseTransactionAsRead = async (
  transactionId: number,
  userId: string,
) => {
  await api.patch(
    `hurdlr/transactions/${userId}/expense-note/${transactionId}/markRead`,
  );
};

export const markIncomeTransactionAsRead = async (
  transactionId: number,
  userId: string,
) => {
  await api.patch(
    `hurdlr/transactions/${userId}/revenue-note/${transactionId}/markRead`,
  );
};

export const postHurdlrExpenseTransactionNote = async (
  userId: string,
  hurdlrId: number,
  note: string,
  author: string,
) => {
  const { data } = await api.post(
    `hurdlr/transactions/${userId}/expense-note`,
    {
      id: hurdlrId,
      note,
      author,
    },
  );

  return data;
};

export const postHurdlrIncomeTransactionNote = async (
  userId: string,
  hurdlrId: number,
  note: string,
  author: string,
) => {
  const { data } = await api.post(
    `hurdlr/transactions/${userId}/revenue-note`,
    {
      id: hurdlrId,
      note,
      author,
    },
  );

  return data;
};

export const getHurdlrExpenseTransactionNote = async (
  userId: string,
  transactionId: number,
) => {
  const { data } = await api.get(
    `hurdlr/transactions/${userId}/expense-note/${transactionId}`,
  );
  return data;
};

export const getHurdlrIncomeTransactionNote = async (
  userId: string,
  transactionId: number,
) => {
  const { data } = await api.get(
    `hurdlr/transactions/${userId}/revenue-note/${transactionId}`,
  );
  return data;
};
