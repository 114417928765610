import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import { get } from 'lodash';
import { IFormField } from './types';

const responsivePlaceHolder = (
  isSmallDevice: boolean,
  placeholder: string | undefined,
) =>
  isSmallDevice ? (
    <option aria-label="None" value="" disabled />
  ) : (
    <MenuItem value="" disabled>
      {placeholder}
    </MenuItem>
  );

const responsiveMenuItem = (
  isSmallDevice: boolean,
  label: any,
  value: string,
  disabled?: boolean,
) =>
  isSmallDevice ? (
    <option key={value} value={value} disabled={disabled}>
      {label}
    </option>
  ) : (
    <MenuItem 
      sx={{
        whiteSpace: 'normal',
      }}
      key={value} 
      value={value} 
      disabled={disabled}
    >
      {label}
    </MenuItem>
  );

export interface TFormationSelectOptions {
  placeholder?: string;
  options?: Array<{ label: string; value: any; disabled?: boolean }>;
  minWidth?: number;
}

type TFormationsSelect = {
  field: IFormField<TFormationSelectOptions>;
  formInstance: UseFormMethods;
};

export const FormationsSelect = ({
  field,
  formInstance,
}: TFormationsSelect) => {
  const { disabled = false } = field;
  const isSmallDevice = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm'),
  );
  const { errors, control } = formInstance;
  const { options: { placeholder, options = [], minWidth } = {} } = field;
  const val = get(formInstance.getValues(), field.name, '');

  const labelId = `${field.name}-label`;

  return (
    <FormControl variant="outlined" fullWidth error={!!errors[field?.name]}>
      <InputLabel id={labelId}>{field.label}</InputLabel>
      <Controller
        as={
          <Select
            labelId={labelId}
            label={field.label}
            native={isSmallDevice}
            placeholder={placeholder}
            value={val}
            defaultValue={field.defaultValue || ''}
            sx={{ minWidth: minWidth ?? '258px' }}
            disabled={disabled}
            data-testid={`field-${field.name}`}
            MenuProps={{
              style: {
                maxHeight: 400,
                maxWidth: 1,
              },
            }}
          >
            {placeholder && responsivePlaceHolder(isSmallDevice, placeholder)}
            {options.map(({ label, value, disabled: optionDisabled }) =>
              responsiveMenuItem(isSmallDevice, label, value, optionDisabled),
            )}
          </Select>
        }
        defaultValue=""
        name={field.name}
        control={control}
      />
      <FormHelperText>{errors[field?.name]?.message || ''}</FormHelperText>
    </FormControl>
  );
};
