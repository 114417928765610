import { Box, Grid, Typography } from "@mui/material";
import { OwnershipSSN } from "components/company/profile/OwnershipSSN";
import { AlertError } from "components/FormationsAlert";
import { DeleteOwner, EditOwner, EditOwnershipPercentage } from "components/ProgressTracker/Incorporation/helpers";
import { useOwners } from "hooks/api";
import { Owner } from "models/company";
import { useMemo } from "react";

interface MobileOwnershipDetailsProps {
  companyId: string | undefined;
  allowEdit: boolean;
}

export const MobileOwnershipDetails = ({ companyId, allowEdit }: MobileOwnershipDetailsProps) => {
  const { owners } = useOwners(companyId);

  const ownershipPercentageWarning = useMemo(() => {
    const totalPercentage = owners?.reduce(
      (accumulator, owner) => accumulator + (owner.ownershipPercentage ?? 0),
      0,
    );

    return totalPercentage !== 100;
  }, [owners]);

  if (!owners || owners.length === 0) {
    return (
      <Typography variant="body1" component="p" sx={{ mt: 2 }}>
        No ownership details available
      </Typography>
    );
  }

  return (
    <>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        {owners.map((owner: Owner) => (
          <Box
            key={owner.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              py: 2,
              borderRadius: '6px',
              border: '1px solid #0D22593B',
            }}
          >
            <Box sx={{ px: '24px', py: '6px' }}>
              <Typography variant='body3S'>
                {owner.name?.First} {owner.name?.Last}
              </Typography>
            </Box>
            <Box sx={{ px: '24px', py: '6px' }}>
              <Typography variant='body3S'>
                {owner.dob ?? 'No DOB'}
              </Typography>
            </Box>
            <Box sx={{ px: '24px', py: '6px' }}>
              <Typography variant='body3S'>
                <OwnershipSSN ownerId={owner.id} companyId={companyId} />
              </Typography>
            </Box>
            <Box sx={{ px: '24px', py: '6px' }}>
              {allowEdit ? (
                <EditOwnershipPercentage owner={owner} />
              ) : (
                <Typography variant='body3S'>
                  {owner.ownershipPercentage}%
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                px: '16px',
                pt: '12px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DeleteOwner owner={owner} allowEdit={allowEdit} confirmText="&nbsp;Delete" />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EditOwner
                  ownerId={owner.id}
                  companyId={owner.companyId}
                  accountId={owner.accountId}
                  isPrimary={owner.isPrimary}
                  allowEdit={allowEdit}
                  confirmText="&nbsp;Edit"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {ownershipPercentageWarning && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <AlertError text="Total ownership must equal 100%. Please review the ownership percentage before you can continue." />
        </Grid>
      )}
    </>
  );
}