import {
  Container,
  Stepper,
} from '@mui/material';
import { Step, StepLabel, StepIcon } from 'components/common';
import { useMemo } from 'react';
import { Company } from 'models/company';

enum IncorporationStatus {
  NEW = 0,
  INCORPORATION_SUBMITTED = 1,
  INCORPORATION_COMPLETED = 2,
  COMPLETED = 3,
  ACTION_NEEDED = -1,
}

export const IncorporationStatusStepper = ({
  company,
}: {
  company: Company;
}) => {
  const status = company?.bibHubspot?.ticketStatus ?? 'NEW';
  const stepNum = useMemo(() => {
    switch (status) {
      case 'NEW':
        return IncorporationStatus.NEW;
      case 'INCORPORATION_SUBMITTED':
        return IncorporationStatus.INCORPORATION_SUBMITTED;
      case 'INCORPORATION_COMPLETED':
        return IncorporationStatus.INCORPORATION_COMPLETED;
      case 'COMPLETED':
        return IncorporationStatus.COMPLETED;
      default:
        return IncorporationStatus.ACTION_NEEDED;
    }
  }, [status]);

  return (
    <Container sx={{ maxWidth: 900 }}>
      <Stepper>
        {status === 'ACTION_NEEDED' ? (
          <Step data-testid="status-action-needed">
            <StepLabel StepIconComponent={StepIcon} error>
              Action Required
            </StepLabel>
          </Step>
        ) : (
          <Step
            data-testid="status-new"
            completed={stepNum >= IncorporationStatus.NEW}
          >
            <StepLabel StepIconComponent={StepIcon}>In Progress</StepLabel>
          </Step>
        )}
        <Step
          data-testid="status-incorporation-submitted"
          completed={stepNum >= IncorporationStatus.INCORPORATION_SUBMITTED}
        >
          <StepLabel StepIconComponent={StepIcon}>
            Submitted For Incorporation
          </StepLabel>
        </Step>
        <Step
          data-testid="status-incorporation-completed"
          completed={stepNum >= IncorporationStatus.INCORPORATION_COMPLETED}
        >
          <StepLabel StepIconComponent={StepIcon}>
            Federal & state review
          </StepLabel>
        </Step>
        <Step
          data-testid="status-completed"
          completed={stepNum >= IncorporationStatus.COMPLETED}
        >
          <StepLabel StepIconComponent={StepIcon}>Filed</StepLabel>
        </Step>
      </Stepper>
    </Container>
  );
};
