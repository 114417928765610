import { UseFormMethods } from 'react-hook-form';
import React from 'react';
import { IFormField } from './types';
import { FormationsTextField } from './FormationsTextField';
import { FormationsSecuredField } from './FormationsSecureField';
import { FormationsNumber } from './FormationsNumber';
import { FormationsSelect } from './FormationsSelect';
import { FormationsCheckbox } from './FormationsCheckbox';
import { FormationsAutoComplete } from './FormationsAutoComplete';
import { FormationsCustom } from './FormationsCustom';
import { FormationsDate } from './FormationsDate';
import { FormationsRadioField } from './FormationsRadioField';
import { FormationsTextArea } from './FormationsTextArea';
import { FormationsRichTextEditor } from './RichTextEditor';
import { FormationsPhoneField } from './FormationsPhone';
import { FormationsSuggestionField } from './FormationsSuggestionNameField';
import { FormationsCheckboxList } from './FormationsCheckboxList';
import { FormationsCurrencyField } from './FormationsCurrencyField';
import { FormationsStaticDatePicker } from './FormationsStaticDatePicker';
import { FormationsMultiSelect } from './FormationsMultiSelect';
import { FormationsRichText } from './RichText';

export enum FormationsFormFields {
  Text = 'text',
  DatePicker = 'datepicker',
  StaticDatePicker = 'staticDatePicker',
  Suggested = 'suggested',
  AutoComplete = 'autoComplete',
  TextArea = 'textArea',
  Select = 'select',
  MultiSelect = 'multiSelect',
  RadioField = 'radio',
  CheckboxField = 'checkbox',
  CheckboxList = 'CheckboxList',
  Number = 'number',
  Phone = 'phone',
  SecuredText = 'securedText',
  Custom = 'custom',
  Currency = 'currency',
  // use RichText if you are creating a new rich text field
  RichText = 'richText',
  // only use Editor if you are editing an existing rich text field
  RichTextEditor = 'richTextEditor',
  None = 'none',
}

export const renderField = (
  field: IFormField,
  formInstance: UseFormMethods<any>,
) => {
  switch (field.type) {
    case FormationsFormFields.Text:
      return <FormationsTextField field={field} formInstance={formInstance} />;
    case FormationsFormFields.RichText:
      return <FormationsRichText field={field} formInstance={formInstance} />;
    case FormationsFormFields.RichTextEditor:
      return (
        <FormationsRichTextEditor field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.TextArea:
      return <FormationsTextArea field={field} formInstance={formInstance} />;
    case FormationsFormFields.Number:
      return <FormationsNumber field={field} formInstance={formInstance} />;
    case FormationsFormFields.Phone:
      return <FormationsPhoneField field={field} formInstance={formInstance} />;
    case FormationsFormFields.Select:
      return <FormationsSelect field={field} formInstance={formInstance} />;
    case FormationsFormFields.MultiSelect:
      return (
        <FormationsMultiSelect field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.CheckboxField:
      return <FormationsCheckbox field={field} formInstance={formInstance} />;
    case FormationsFormFields.AutoComplete:
      return (
        <FormationsAutoComplete field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.SecuredText:
      return (
        <FormationsSecuredField field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.DatePicker:
      return <FormationsDate field={field} formInstance={formInstance} />;
    case FormationsFormFields.StaticDatePicker:
      return (
        <FormationsStaticDatePicker field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.RadioField:
      return <FormationsRadioField field={field} formInstance={formInstance} />;
    case FormationsFormFields.Suggested:
      return (
        <FormationsSuggestionField field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.CheckboxList:
      return (
        <FormationsCheckboxList field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.Currency:
      return (
        <FormationsCurrencyField field={field} formInstance={formInstance} />
      );
    case FormationsFormFields.Custom:
      return <FormationsCustom field={field} formInstance={formInstance} />;
    default:
      return null;
  }
};
