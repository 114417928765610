import React, { useEffect } from 'react';
import {
  useFlags as useFlagsOrigin,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { useCurrentUser } from 'hooks/api';
import { NotFoundPage } from 'views/NotFoundPage';
import { CONFIG } from 'config';
import { ALLOWED_ENVIRONMENTS } from 'helpers/featureFlag';
import { checkFeaturePermission } from 'components/common/sidebar/helpers';
import {
  FeatureName,
  FormationFeature,
  TransactionDataSource,
} from 'services/features';
import { useCurrentUserFeatureList } from './api/useFeatures';

export const FLAGS = {
  LAUNCH: 'launch',
  BETA: 'beta',
  DEV: 'dev',
  PUBLISH: 'publish',
  CHECKINSURVEY: 'checkInSurvey',
  FEEDBACKFORM: 'feedbackForm',
  OILLIST: 'oilList',
  VENDOR_MANAGEMENT: 'vendorManagement',
};

// DEV
export const ACCOUNTING_REPORTS_FLAG = FLAGS.DEV;
export const PAYROLL_SETTINGS_BUSINESS_BANK_FLAG = FLAGS.DEV;
export const PAYROLL_SETTINGS_DIRECT_DEPOSIT_FLAG = FLAGS.DEV;
export const TOP_NAV_NOTIFICATION_MENU_FLAG = FLAGS.DEV;
export const NEW_ONBOARDING_FLAG = FLAGS.DEV;
export const ADMIN_BUSINESS_HEALTH = FLAGS.DEV;

export const DOCUMENT_HUB_QUICK_FILTER_FLAG = FLAGS.DEV;
export const DOCUMENT_HUB_STATUS_FLAG = FLAGS.DEV;
export const DOCUMENT_HUB_FAVOURITE_FLAG = FLAGS.DEV;
export const DOCUMENT_HUB_TOGGLE_VERSION_FLAG = FLAGS.DEV;

// BETA

// LAUNCH

// PUBLISH
export const BUSINESS_IN_A_BOX = FLAGS.PUBLISH;
export const IRS_MILEAGE_RATE = FLAGS.PUBLISH;
export const ACCOUNTING_BUSINESS_HOME_FLAG = FLAGS.PUBLISH;
export const ACCOUNTING_BUSINESS_MILES_FLAG = FLAGS.PUBLISH;
export const ACCOUNTING_BUSINESS_MILES_IQ_UPLOAD = FLAGS.PUBLISH;
export const ADMIN_BUSINESS_USE_OF_HOME = FLAGS.PUBLISH;
export const ADMIN_BUSINESSMILES = FLAGS.PUBLISH;
export const ADMIN_XERO_CONNECTION_HISTORY = FLAGS.PUBLISH;
export const DOCUMENT_HUB_NOTE_FLAG = FLAGS.PUBLISH;
export const DOCUMENT_HUB_FLAG = FLAGS.PUBLISH;
export const NEW_DASHBOARD_FLAG = FLAGS.PUBLISH;
export const PL_GRAPH_FLAG = FLAGS.PUBLISH;
export const TODO_FLAG = FLAGS.PUBLISH;
export const UPCOMING_EVENTS_FLAG = FLAGS.PUBLISH;
export const ACCOUNTING_FLAG = FLAGS.PUBLISH;
export const PAYROLL_FLAG = FLAGS.PUBLISH;
export const GETTING_STARTED_FLAG = FLAGS.PUBLISH;
export const TAX_STATUS_FLAG = FLAGS.PUBLISH;
export const PAYROLL_ONBOARDING_FLAG = FLAGS.PUBLISH;
export const ADMIN_PAYROLL_ONBOARDING = FLAGS.PUBLISH;
export const ACCOUNTING_TRANSACTION_NOTES = FLAGS.PUBLISH;
export const OIL_CUSTOMER_FLAG = FLAGS.PUBLISH;

// Survey Flag
export const CHECK_IN_SURVEY_FLAG = FLAGS.CHECKINSURVEY;

// Vendor Flag
export const VENDOR_MANAGEMENT_FLAG = FLAGS.VENDOR_MANAGEMENT;

// Formations Feature Flags
export const DATA_SOURCE_TOGGLE = FeatureName.DataSourceToggle;
export const XERO_DATA_SOURCE = TransactionDataSource.Xero;
export const HURDLR_DATA_SOURCE = TransactionDataSource.Hurdlr;
export const INVOICING_FLAG = FeatureName.Invoicing;

export const useFlags = () => {
  const flags = useFlagsOrigin();
  if (
    ALLOWED_ENVIRONMENTS.includes(CONFIG.environment) &&
    CONFIG.sentry.enabled
  ) {
    return {
      ...flags,
      [FLAGS.PUBLISH]: true,
    };
  }
  return {
    [FLAGS.PUBLISH]: true, // publish flag will be true by default
    [FLAGS.LAUNCH]: true, // launch ready features will be false by default
    [FLAGS.BETA]: true, // beta features will be false by default
    [FLAGS.DEV]: true, // dev features will be false by default
    [FLAGS.CHECKINSURVEY]: true, // checkin survey for customer will be false by default
    [FLAGS.FEEDBACKFORM]: true,
    [FLAGS.OILLIST]: true, // if HURDLR is true, HURDLRBANKCONNECTION will be true by default
    [FLAGS.VENDOR_MANAGEMENT]: true,
  };
};

export const useFeatureFlag = (key: string): boolean => {
  const flags = useFlags();
  const featureList: FormationFeature[] = useCurrentUserFeatureList();
  return checkFeaturePermission(flags, featureList, key);
};

export const useDataSourcesFeatureFlag = (): TransactionDataSource => {
  const featureList: FormationFeature[] = useCurrentUserFeatureList();
  const formationsFeatureFlag = featureList.find(
    (f) => f.featureName === DATA_SOURCE_TOGGLE,
  );
  if (formationsFeatureFlag?.data?.source) {
    return formationsFeatureFlag?.data?.source;
  }
  return TransactionDataSource.Hurdlr;
};

export const useHurdlrDataSource = () => {
  const dataSource = useDataSourcesFeatureFlag();
  return dataSource === TransactionDataSource.Hurdlr;
};

export const useXeroDataSource = () => {
  const dataSource = useDataSourcesFeatureFlag();
  return dataSource === TransactionDataSource.Xero;
};

export const withFeatureFlag =
  (
    key: string,
    DefaultComponent: React.FunctionComponent<any> | null = NotFoundPage,
  ) =>
  (WrappedComponent: React.FunctionComponent<any>) =>
  (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isFeatureFlagEnabled = useFeatureFlag(key);
    if (isFeatureFlagEnabled) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} />;
    }
    return DefaultComponent == null ? null : <DefaultComponent />;
  };

export const useFeatureFlagIdentify = () => {
  const LDClient = useLDClient();
  const { currentUser, isSuccess } = useCurrentUser();

  useEffect(() => {
    if (LDClient && currentUser && isSuccess) {
      LDClient.identify({
        kind: 'user',
        key: currentUser.id,
        email: currentUser.email,
        firstName: currentUser?.name?.first,
        lastName: currentUser?.name?.last,
      }).catch((e) => {
        console.log(JSON.stringify(e));
      });
    }
  }, [currentUser, LDClient]);
};
