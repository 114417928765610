import { Link, useLocation } from 'react-router-dom';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  SvgIcon,
  Grid,
  Typography,
  Badge,
  Icon as EmptyIcon,
} from '@mui/material';
import {
  CustomIconComponent,
  ExpandMoreIcon as ExpandMore,
  KeyboardArrowRightIcon,
} from 'components/common/Icon';
import { useLeftNavNotifications } from 'hooks/api/useTodos';
import { styled } from '@mui/material/styles';
import React, {
  useMemo,
  SetStateAction,
  Dispatch,
  CSSProperties,
  ReactElement,
} from 'react';
import { useMenuCollapsible } from 'hooks/useMenuCollapsible';
import {
  useFlags,
  ACCOUNTING_BUSINESS_HOME_FLAG,
  ACCOUNTING_BUSINESS_MILES_FLAG,
  CHECK_IN_SURVEY_FLAG,
  OIL_CUSTOMER_FLAG,
  useFeatureFlag,
  useHurdlrDataSource,
  VENDOR_MANAGEMENT_FLAG,
} from 'hooks/useFeatureFlag';
import {
  ApiListTransactions,
  UNKNOWN_TRANSACTION_CATEGORY_CODE,
} from 'services/xero';
import { useTransactions } from 'hooks/api/useTransactions';
import { useSidebarOpen } from 'components/common/sidebar/desktop/sidebarContext';
import { useCurrentAccount, usePathWithSpoof } from 'hooks/api';
import { SidebarMenuID } from 'enums';
import {
  filterTodoByFeatureFlag,
  filterTodoByStatus,
} from 'views/dashboard/insight/todos/helpers';
import {
  ITodoItem,
  ITodoItemType,
  ITodoItemStatus,
} from 'views/dashboard/insight/todos/types';
import { intersection } from 'lodash';
import { useCurrentUserFeatureList } from 'hooks/api/useFeatures';
import { IAccount } from 'models/account';
import { isAccountBusinessInABox } from 'components/ProgressTracker/util';
import { checkFeaturePermission, filterVisibleActions } from '../helpers';
import { IAction, ISubAction } from '../constant';
import { isSamePathWithSpoof } from '../../../../helpers/routes';

export const PrimaryChipBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#FF4E4E',
    color: '#FFFFFF',
    top: '50%',
    right: '50%',
  },
  '& .MuiBadge-standard': {
    fontSize: '8px',
    height: '18px',
    width: '18px',
    borderRadius: '50%',
    minWidth: 0,
  },
}));

const SideBarListItem = styled(ListItemButton)(({ theme }) => ({
  height: '72px',
  padding: theme.spacing(3),
  '& .sideBarItemIcon': {
    color: '#696C80',
  },
  '& .MuiTypography-root': {
    color: '#696C80',
  },
  '& .sideBarItemSecondaryIcon': {
    color: '#696C80',
  },
  '&:hover': {
    backgroundColor: '#FFFFFF',
    '& .sideBarItemIcon': {
      color: theme.palette.gray[100],
    },
    '& .MuiTypography-root': {
      color: theme.palette.gray[100],
    },
    '& .sideBarItemSecondaryIcon': {
      color: theme.palette.gray[100],
    },
  },
  '&:focus': {
    backgroundColor: theme.palette.gray[20],
    '& .sideBarItemIcon': {
      color: theme.palette.gray[100],
    },
    '& .MuiTypography-root': {
      color: theme.palette.gray[100],
    },
    '& .sideBarItemSecondaryIcon': {
      color: theme.palette.gray[100],
    },
  },
  '&.Mui-disabled': {
    opacity: 0.5,
  },
  '&.Mui-selected': {
    backgroundColor: '#FFFFFF',
    '& .sideBarItemIcon': {
      color: '#696C80',
    },
    '& .MuiTypography-root': {
      color: '#696C80',
    },
    '& .sideBarItemSecondaryIcon': {
      color: '#696C80',
    },
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '&.MuiListItemButton-root:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '8px',
      height: '56px',
      backgroundColor: theme.palette.blue.primary,
      borderTopRightRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
      left: '0px',
    },
  },
}));

// map with keys that are menu item ids and values that are all the corresponding sub-level todo item types
const menuItemToSubMenuTodosMap = {
  accounting: [
    ITodoItemType.BusinessUseOfHome,
    ITodoItemType.UnknownTransactions,
    ITodoItemType.AutoMileageExpense,
    ITodoItemType.BankConnectionError,
    ITodoItemType.VendorW9Upload,
  ],
  taxes: [ITodoItemType.CheckInSurvey, ITodoItemType.OilList],
};

// map with keys that are todo item types and values that are the corresponding menu item ids
const todoToMenuItemMap = {
  [ITodoItemType.BusinessUseOfHome]: 'businessUseOfHome',
  [ITodoItemType.UnknownTransactions]: 'unknownTransactions',
  [ITodoItemType.AutoMileageExpense]: 'businessMiles',
  [ITodoItemType.CheckInSurvey]: 'tax-form',
  [ITodoItemType.OilList]: 'oil-list',
  [ITodoItemType.BankConnectionError]: 'bankAccounts',
  [ITodoItemType.VendorW9Upload]: 'vendors',
};

const getNotificationCount = (
  filteredTodos: ITodoItem[],
  id: string,
  transactions: ApiListTransactions,
  usingHurdlr: boolean,
) => {
  let notificationCount = 0;

  if (Object.keys(menuItemToSubMenuTodosMap).includes(id)) {
    const subItemTodos =
      menuItemToSubMenuTodosMap[id as keyof typeof menuItemToSubMenuTodosMap];
    let todosList = filteredTodos.map((todo) => todo.type);

    // only count OIL todos once for now
    if (todosList.includes(ITodoItemType.OilList)) {
      const rest = todosList.filter((todo) => todo !== ITodoItemType.OilList);
      todosList = [...rest, ITodoItemType.OilList];
    }

    const notifyList = intersection(subItemTodos, todosList);
    const counts: { [key: string]: number } = {};
    todosList.forEach((item) => {
      counts[item] = (counts[item] || 0) + 1;
      if (counts[item] > 1 && notifyList.includes(item)) {
        notifyList.push(item); // adding back the duplicates in todoslist barring the first occurrence
      }
    });
    notifyList.forEach((todoType) => {
      if (todoType === ITodoItemType.UnknownTransactions && !usingHurdlr) {
        notificationCount += transactions.pageInfo.totalCount;
      } else if (todoType !== ITodoItemType.UnknownTransactions) {
        notificationCount += 1;
      }
    });
  } else {
    const foundTodos = filteredTodos.filter(
      (todo) =>
        todoToMenuItemMap[todo.type as keyof typeof todoToMenuItemMap] === id,
    );

    foundTodos.forEach((todo) => {
      if (todo.type === ITodoItemType.UnknownTransactions && !usingHurdlr) {
        notificationCount += transactions.pageInfo.totalCount;
      } else if (todo.type === ITodoItemType.OilList) {
        notificationCount += 1;
      } else if (todo.type !== ITodoItemType.UnknownTransactions) {
        notificationCount += 1;
      }
    });

    // we only count oil todo once for now
    if (id === 'oil-list') {
      const oilTodos = foundTodos.filter(
        (todo) => todo.type === ITodoItemType.OilList,
      ).length;
      if (oilTodos > 0) {
        notificationCount = notificationCount + 1 - oilTodos;
      }
    }
  }

  return notificationCount;
};

type Props = {
  Icon?: typeof SvgIcon | CustomIconComponent;
  style?: CSSProperties;
  sidebarOpen: boolean;
  text: string;
  secondaryIcon: ReactElement | null;
  notificationIcon: ReactElement | null;
  handleClick?: () => void;
};

const SideBarItemContent = ({
  Icon,
  style,
  sidebarOpen,
  text,
  secondaryIcon,
  notificationIcon,
  handleClick,
}: Props) => (
  <Grid
    container
    alignItems="center"
    flexWrap="nowrap"
    style={style}
    onClick={handleClick}
  >
    {Icon && (
      <Grid item sx={{ mr: 2 }} display="flex" alignItems="center">
        <ListItemIcon sx={{ minWidth: 0 }}>
          <Icon className="sideBarItemIcon" fontSize="medium" />
        </ListItemIcon>
      </Grid>
    )}
    <Grid item container wrap="nowrap">
      <Grid
        container
        item
        sx={{
          width: secondaryIcon || notificationIcon ? 'fit-content' : '100%',
        }}
      >
        <ListItemText
          sx={{
            opacity: sidebarOpen ? 1 : 0,
            whiteSpace: 'normal',
          }}
        >
          <Typography variant="body2S">{text}</Typography>
        </ListItemText>
      </Grid>
      {notificationIcon && secondaryIcon && (
        <Grid
          item
          display="flex"
          alignItems="center"
          flexWrap="nowrap"
          sx={{ marginLeft: 'auto' }}
        >
          {notificationIcon}
        </Grid>
      )}
      <Grid
        item
        sx={{
          marginLeft: notificationIcon && secondaryIcon ? 0 : 'auto',
        }}
        display="flex"
        alignItems="center"
        flexWrap="nowrap"
      >
        {secondaryIcon ?? notificationIcon}
      </Grid>
    </Grid>
  </Grid>
);

type MenuItemProps = Omit<IAction, 'Icon' | 'subActions'> & {
  Icon?: typeof SvgIcon | CustomIconComponent;
  subActions?: ISubAction[];
  active?: boolean;
  style?: CSSProperties;
  showSubmenuId?: string;
  setShowSubmenuId?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  shouldRender?: (acc: IAccount) => boolean;
  shouldDisable?: (acc: IAccount) => boolean;
  handleClose?: () => void;
};

export const MenuItem = ({
  id,
  path,
  Icon,
  text,
  actionPermission,
  subActions = [],
  disabledForStatus = [],
  hiddenForStatus = [],
  disabled = false,
  style,
  shouldRender = () => true,
  shouldDisable = () => false,
  handleClose,
}: MenuItemProps) => {
  const { pathname } = useLocation();
  const sidebarOpen = useSidebarOpen();
  const { currentAccount } = useCurrentAccount();
  const customerStatus = currentAccount?.status?.label ?? '';
  const [openMenuId, setOpenMenuId] = useMenuCollapsible();
  const open = useMemo(() => openMenuId === id, [openMenuId]);

  // Hooks for managing badge notifications (from Todos) on sidebar
  const accountID = currentAccount?.id;
  const isUsingHurdlrData = useHurdlrDataSource();

  const { todos } = useLeftNavNotifications(accountID);
  const showBusinessMilesTodo = useFeatureFlag(ACCOUNTING_BUSINESS_MILES_FLAG);
  const showBUHTodo = useFeatureFlag(ACCOUNTING_BUSINESS_HOME_FLAG);
  const showCheckInSurveyTodo = useFeatureFlag(CHECK_IN_SURVEY_FLAG);
  const showOIListTodo = useFeatureFlag(OIL_CUSTOMER_FLAG);
  const showVendorW9UploadTodo = useFeatureFlag(VENDOR_MANAGEMENT_FLAG);
  const { transactions } = useTransactions(accountID ?? '', {
    category: [UNKNOWN_TRANSACTION_CATEGORY_CODE],
  });

  const isDisabledMenu = !currentAccount || shouldDisable(currentAccount);

  const filteredTodos: ITodoItem[] = useMemo(() => {
    let todosList: ITodoItem[] = [];
    if (todos) {
      todosList = filterTodoByFeatureFlag(
        todos,
        ITodoItemType.AutoMileageExpense,
        showBusinessMilesTodo && !isAccountBusinessInABox(currentAccount),
      );
      todosList = filterTodoByFeatureFlag(
        todosList,
        ITodoItemType.BusinessUseOfHome,
        showBUHTodo && !isAccountBusinessInABox(currentAccount),
      );
      todosList = filterTodoByFeatureFlag(
        todosList,
        ITodoItemType.CheckInSurvey,
        showCheckInSurveyTodo && !isAccountBusinessInABox(currentAccount),
      );
      todosList = filterTodoByFeatureFlag(
        todosList,
        ITodoItemType.OilList,
        showOIListTodo,
      );
      todosList = filterTodoByFeatureFlag(
        todosList,
        ITodoItemType.VendorW9Upload,
        showVendorW9UploadTodo,
      );
      todosList = filterTodoByStatus(todosList, ITodoItemStatus.Open);
    }
    return todosList;
  }, [
    showBUHTodo,
    showBusinessMilesTodo,
    showCheckInSurveyTodo,
    showOIListTodo,
    showVendorW9UploadTodo,
    todos,
    currentAccount,
  ]);

  const notificationCount = getNotificationCount(
    filteredTodos,
    id,
    transactions,
    isUsingHurdlrData,
  );

  const flags = useFlags();
  const featureList = useCurrentUserFeatureList();

  const filteredSubActions = useMemo(
    () => filterVisibleActions(subActions || [], flags, featureList),
    [featureList, flags, subActions],
  );
  const permissionDisabled = !checkFeaturePermission(
    flags,
    featureList,
    actionPermission,
  );
  const hasSubActions =
    Array.isArray(filteredSubActions) && filteredSubActions.length > 0;
  // we use startWith to match path, this way any action perform under that particular menu should have the parent item highlighted
  // for example dashboard/settings-and-requests/request-change vs dashboard/settings-and-requests
  const isActive =
    path !== '' &&
    path != null &&
    (pathname.startsWith(path) || isSamePathWithSpoof(path, pathname));
  const pathWithSpoof = usePathWithSpoof(path);
  let buttonProps: Partial<typeof ListItemButton> = {
    component: Link,
    to: pathWithSpoof,
  };

  let secondaryIcon = null;
  let notificationIcon = null;

  if (hasSubActions) {
    buttonProps = {
      onClick: () => {
        if (!open) setOpenMenuId(id as SidebarMenuID);
        else setOpenMenuId(null);
      },
    };
    secondaryIcon = open ? (
      <ExpandMore className="sideBarItemSecondaryIcon" />
    ) : (
      <KeyboardArrowRightIcon className="sideBarItemSecondaryIcon" />
    );
  }

  if (notificationCount) {
    notificationIcon = (
      <PrimaryChipBadge
        badgeContent={
          notificationCount === 1 && id !== 'unknownTransactions'
            ? ' '
            : notificationCount
        }
        variant={
          notificationCount === 1 && id !== 'unknownTransactions'
            ? 'dot'
            : 'standard'
        }
      >
        <EmptyIcon />
      </PrimaryChipBadge>
    );
  }

  if (hiddenForStatus.includes(customerStatus)) {
    return null;
  }

  if (currentAccount == null || shouldRender(currentAccount) === false) {
    return null;
  }

  const handleSidebarItemClick = () => {
    if (handleClose && !hasSubActions) {
      handleClose();
    }
  };

  return (
    <>
      <SideBarListItem
        key={id}
        data-testid={`menu_item_${id}`}
        selected={isActive}
        {...buttonProps}
        disabled={
          disabled ||
          permissionDisabled ||
          disabledForStatus.includes(customerStatus) ||
          isDisabledMenu
        }
      >
        <SideBarItemContent
          Icon={Icon}
          style={style}
          sidebarOpen={sidebarOpen}
          text={text}
          secondaryIcon={secondaryIcon}
          notificationIcon={notificationIcon}
          handleClick={handleSidebarItemClick}
        />
      </SideBarListItem>
      {hasSubActions && (
        <Collapse in={open} timeout="auto">
          <List sx={{ p: 0 }}>
            {filteredSubActions.map((item) => (
              <MenuItem
                key={item.id}
                {...item}
                style={{
                  paddingLeft: '40px',
                }}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
