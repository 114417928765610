import { Button, Typography } from '@mui/material';
import TourOutlinedIcon from '@mui/icons-material/TourOutlined';
import { styled } from '@mui/material/styles';

const AnimatedButton = styled(Button)(({ theme }) => ({
  minWidth: '40px',
  height: '40px',
  transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  padding: '8px',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  border: 'none',

  '& .MuiButton-startIcon': {
    margin: 0,
    color: theme.palette.primary.main,
    transition: 'margin-right 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  },

  '& .button-text': {
    opacity: 0,
    width: 0,
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
  },

  '&:hover': {
    width: '130px',
    backgroundColor: '#FFFDC2',
    border: 'none',
    boxShadow:
      '0px 1px 18px 0px #0D22590D, 0px 6px 10px 0px #0D22590D, 0px 3px 5px -1px #0000000D',
    borderRadius: '20px',
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(1),
    },
    '& .button-text': {
      opacity: 1,
      width: 'auto',
      visibility: 'visible',
    },
  },
}));

export const TourButton = () => (
  <AnimatedButton
    variant="outlined"
    color="primary"
    startIcon={<TourOutlinedIcon />}
  >
    <Typography variant="body3B" className="button-text">
      Take a tour
    </Typography>
  </AnimatedButton>
);
