import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { RedirectToHomeOrLoginPage } from 'components/RedirectToHomeOrLoginPage';
import { useCurrentUserRole } from 'hooks/api';
import { ChurnZeroSetupContact } from 'components/LoadScripts';
import TaxNamesManagement from 'views/taxes/TaxNamesManagement';
import { CONFIG } from '../config';
import { SideBarLayout } from '../components/common';
import { Routes as AppRoutes, SpoofRoutes } from '../fnRoutes';

const Accounts = lazy(() => import('views/accounts'));
const GustoSettings = lazy(() => import('views/GustoSettings'));
const InaccessibleCompanies = lazy(
  () => import('views/GustoInaccessibleCompanies'),
);
const IrsMileageRate = lazy(() => import('views/IrsMileageRate'));
const TaxBracket = lazy(() => import('components/taxBracket/TaxBracket'));
const CSMMEetingLink = lazy(
  () => import('components/CSMMeetingLink/CSMMeetingLink'),
);
const IndustryOptions = lazy(
  () => import('components/IndustryOptions/IndustryOptions'),
);
const StandardDeduction = lazy(
  () => import('components/StandardDeduction/StandardDeduction'),
);
const Documents = lazy(() => import('views/Documents/Documents'));
const Company = lazy(() => import('views/Company'));
const Welcome = lazy(() => import('views/dashboard'));
const MyAccount = lazy(() => import('views/MyAccount'));
const YearToDateAccount = lazy(() => import('views/YearToDateAccount'));
const NotFoundPage = lazy(() =>
  import('views/NotFoundPage').then((df) => ({ default: df.NotFoundPage })),
);
const AddTransaction = lazy(() => import('views/accounting/AddTransaction'));
const AllTransactions = lazy(() => import('views/accounting/AllTransactions'));
const Vendors = lazy(() => import('views/accounting/Vendors'));
const UnknownTransactions = lazy(
  () => import('views/accounting/UnknownTransactions'),
);
const Reports = lazy(() => import('views/accounting/Reports'));
const Invoicing = lazy(() => import('views/invoicing/Invoicing'));
const BusinessMiles = lazy(() => import('views/accounting/BusinessMiles'));
const BusinessUseOfHome = lazy(
  () => import('views/accounting/BusinessUseOfHome'),
);
const PayrollMain = lazy(() => import('views/payroll/PayrollMain'));
const PayrollSettings = lazy(() => import('views/payroll/PayrollSettings'));
const RequestPayrollChange = lazy(
  () =>
    import('views/payroll/settings/requestPayrollChange/RequestPayrollChange'),
);
const PayrollOnboarding = lazy(() => import('views/payroll/PayrollOnboarding'));

const RequestOffCyclePayroll = lazy(
  () => import('views/payroll/settings/requestPayroll/RequestOffCyclePayroll'),
);

const RequestOffCyclePayrollSuccess = lazy(
  () =>
    import(
      'views/payroll/settings/requestPayroll/RequestOffCyclePayrollSuccess'
    ),
);

const TaxesDirectPayment = lazy(() => import('views/taxes/TaxesDirectPayment'));
const CustomerOIL = lazy(() => import('views/OIL/CustomerOIL'));

const CompanyAccount = lazy(() => import('views/company/MyBusiness'));
const TaxSurveyForm = lazy(() => import('views/TaxSurveyForm'));
const AccountSetting = lazy(() => import('views/preferences/AccountSetting'));
const MySubscription = lazy(
  () => import('views/preferences/MySubscription/MySubscription'),
);
const ActiveSurvey = lazy(() => import('components/activeSurvey/ActiveSurvey'));
const UpcomingEvents = lazy(
  () => import('components/UpcomingEvents/UpcomingEvents'),
);
const ActiveTaxYears = lazy(
  () => import('components/ActiveTaxYears/ActiveTaxYears'),
);
const TaxLiability = lazy(() => import('components/TaxLiability/TaxLiability'));
const TaxDeadline = lazy(() => import('components/TaxDeadline/TaxDeadline'));
const OilTemplates = lazy(() => import('components/OilTemplates/OilTemplates'));
const HurdlrAdminUI = lazy(
  () => import('components/HurdlrAdminUI/HurdlrAdminUI'),
);
const WorknetUI = lazy(() => import('components/Worknet/WorknetUI'));

const OnboardingHome = lazy(() => import('components/Onboarding/Home'));
const OnboardingBusinessFormation = lazy(
  () => import('components/Onboarding/BusinessFormation/index'),
);
const OnboardingBusinessFormationStep1 = lazy(
  () => import('components/Onboarding/BusinessFormation/BFStep1'),
);
const OnboardingBusinessFormationStep2 = lazy(
  () => import('components/Onboarding/BusinessFormation/BFStep2'),
);
const OnboardingBusinessFormationStep3 = lazy(
  () => import('components/Onboarding/BusinessFormation/BFStep3'),
);
const OnboardingBusinessFormationStep4 = lazy(
  () => import('components/Onboarding/BusinessFormation/BFStep4'),
);
const OnboardingBankAccount = lazy(
  () => import('components/Onboarding/BankAccount/index'),
);
const OnboardingBankAccountStep1 = lazy(
  () => import('components/Onboarding/BankAccount/BAStep1'),
);
const OnboardingBankAccountStep2 = lazy(
  () => import('components/Onboarding/BankAccount/BAStep2'),
);
const OnboardingBankAccountStep3 = lazy(
  () => import('components/Onboarding/BankAccount/BAStep3'),
);
const OnboardingAccountSetup = lazy(
  () => import('components/Onboarding/AccountSetup/index'),
);
const OnboardingAccountSetupStep1 = lazy(
  () => import('components/Onboarding/AccountSetup/ASStep1'),
);
const OnboardingAccountSetupStep2 = lazy(
  () => import('components/Onboarding/AccountSetup/ASStep2'),
);
const OnboardingAccountSetupStep3 = lazy(
  () => import('components/Onboarding/AccountSetup/ASStep3'),
);
const BankAccounts = lazy(() =>
  import('views/bankAccount/BankAccounts').then((df) => ({
    default: df.BankAccounts,
  })),
);
const SetupPageView = lazy(() => import('views/setupPage/index'));
const DomainSearchPage = lazy(() => import('views/squarespace/SearchPage'));
const DomainCheckoutPage = lazy(() => import('views/squarespace/CheckoutPage'));
const SqspLinkPage = lazy(() => import('views/squarespace/SqspLinkPage'));
const VendorsImport = lazy(
  () => import('views/accounting/vendors/VendorsImport'),
);

const DomainManagement = lazy(
  () => import('views/squarespace/DomainManagement'),
);
type SidebarRoute = {
  isEnabled?: boolean;
  path: string;
  relativePath?: string;
  role: string;
  exact?: boolean;
  component: React.ComponentType<any>;
};

const generateOnboardingRoutes = ({
  isAdminSpoof = false,
}: {
  isAdminSpoof?: boolean;
}) => {
  const newRoutes = isAdminSpoof ? SpoofRoutes : AppRoutes;
  const routeList: SidebarRoute[] = [
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BUSINESS_FORMATION_STEP_1,
      relativePath: 'business-formation/step-1',
      component: OnboardingBusinessFormationStep1,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BUSINESS_FORMATION_STEP_2,
      relativePath: 'business-formation/step-2',
      component: OnboardingBusinessFormationStep2,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BUSINESS_FORMATION_STEP_3,
      relativePath: 'business-formation/step-3',
      component: OnboardingBusinessFormationStep3,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BUSINESS_FORMATION_STEP_4,
      relativePath: 'business-formation/step-4',
      component: OnboardingBusinessFormationStep4,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BUSINESS_FORMATION,
      relativePath: 'business-formation',
      component: OnboardingBusinessFormation,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_ACCOUNT_SETUP_STEP_1,
      component: OnboardingAccountSetupStep1,
      relativePath: 'account-setup/step-1',
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_ACCOUNT_SETUP_STEP_2,
      component: OnboardingAccountSetupStep2,
      relativePath: 'account-setup/step-2',
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_ACCOUNT_SETUP_STEP_3,
      relativePath: 'account-setup/step-3',
      component: OnboardingAccountSetupStep3,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_ACCOUNT_SETUP,
      relativePath: 'account-setup',
      component: OnboardingAccountSetup,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BANK_ACCOUNT_STEP_1,
      relativePath: 'bank-account/step-1',
      component: OnboardingBankAccountStep1,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BANK_ACCOUNT_STEP_2,
      relativePath: 'bank-account/step-2',
      component: OnboardingBankAccountStep2,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BANK_ACCOUNT_STEP_3,
      relativePath: 'bank-account/step-3',
      component: OnboardingBankAccountStep3,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING_BANK_ACCOUNT,
      relativePath: '/bank-account',
      component: OnboardingBankAccount,
    },
    {
      role: 'Customer',
      path: newRoutes.ONBOARDING,
      relativePath: '/',
      component: OnboardingHome,
    },
  ];
  return (
    <Routes>
      {routeList.map((r: SidebarRoute) =>
        isAdminSpoof ? (
          <Route path={r.path} element={<r.component />} key={r.path} />
        ) : (
          <Route
            path={r.relativePath ?? r.path}
            element={<r.component />}
            key={r.path}
          />
        ),
      )}
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
};
export const OnboardingRoutes = () =>
  generateOnboardingRoutes({ isAdminSpoof: false });
export const OnboardingRoutesSpoof = () =>
  generateOnboardingRoutes({ isAdminSpoof: true });

const GenerateSidebar = ({
  isAdminSpoof = false,
}: {
  isAdminSpoof?: boolean;
}) => {
  const { isAdmin } = useCurrentUserRole();
  const userRole = isAdmin ? 'staff' : 'Customer';

  const routeList: SidebarRoute[] = [
    {
      role: 'staff',
      path: AppRoutes.ADMIN_HOME,
      relativePath: 'accounts/*',
      component: Accounts,
    },
    {
      role: 'staff',
      path: AppRoutes.TAX_BRACKET,
      relativePath: 'tax-bracket/*',
      component: TaxBracket,
    },
    {
      role: 'staff',
      path: AppRoutes.STANDARD_DEDUCTION,
      relativePath: 'standard-deduction/*',
      component: StandardDeduction,
    },
    {
      role: 'staff',
      path: AppRoutes.SUPERADMIN_IRS_RATE,
      relativePath: 'irs-mileage-rate/*',
      component: IrsMileageRate,
    },
    {
      role: 'staff',
      path: AppRoutes.CSM_MEETING_LINK,
      relativePath: '/csm-meeting-link/*',
      component: CSMMEetingLink,
    },
    {
      role: 'staff',
      path: AppRoutes.ACTIVE_SURVEY,
      relativePath: '/active-survey/*',
      component: ActiveSurvey,
    },
    {
      role: 'staff',
      path: AppRoutes.UPCOMING_EVENTS,
      relativePath: '/upcoming-events/*',
      component: UpcomingEvents,
    },
    {
      role: 'staff',
      path: AppRoutes.OIL_TEMPLATES,
      relativePath: 'oil-templates/*',
      component: OilTemplates,
    },
    {
      role: 'staff',
      path: AppRoutes.HURDLR,
      relativePath: '/hurdlr/*',
      component: HurdlrAdminUI,
    },
    {
      role: 'staff',
      path: AppRoutes.WORKNET,
      relativePath: '/worknet/*',
      component: WorknetUI,
    },
    {
      role: 'staff',
      path: AppRoutes.ACTIVE_TAX_YEARS,
      relativePath: '/active-tax-years/*',
      component: ActiveTaxYears,
    },
    {
      role: 'staff',
      path: AppRoutes.TAX_DEADLINE,
      relativePath: '/tax-deadline/*',
      component: TaxDeadline,
    },
    {
      role: 'staff',
      path: AppRoutes.TAX_LIABILITY,
      relativePath: '/tax-liability/*',
      component: TaxLiability,
    },
    {
      role: 'staff',
      path: AppRoutes.INDUSTRY_OPTIONS,
      relativePath: '/industry-options/*',
      component: IndustryOptions,
    },
    {
      role: 'staff',
      path: AppRoutes.VENDORS_IMPORT,
      relativePath: '/vendor-import/*',
      component: VendorsImport,
    },
    {
      role: 'Customer',
      path: AppRoutes.CUSTOMER_HOME,
      relativePath: 'welcome/',
      component: Welcome,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNT,
      relativePath: 'account/',
      component: MyAccount,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNT_SETTING,
      relativePath: 'preferences/account-setting',
      component: AccountSetting,
    },
    {
      role: 'Customer',
      path: AppRoutes.MY_SUBSCRIPTION,
      relativePath: 'my-subscription',
      component: MySubscription,
    },
    {
      role: 'Customer',
      path: AppRoutes.DOCUMENTS,
      relativePath: 'documents',
      component: Documents,
      isEnabled: CONFIG.enableDocuments,
    },
    {
      role: 'Customer',
      path: AppRoutes.TAX_LIABILITY_FORM,
      relativePath: 'tax-form',
      component: TaxSurveyForm,
    },
    {
      role: 'staff',
      path: AppRoutes.GUSTO,
      relativePath: 'settings',
      component: GustoSettings,
    },
    {
      role: 'staff',
      path: AppRoutes.GUSTO_INACCESSIBLE_COMPANIES,
      relativePath: 'settings/inaccessible-companies',
      component: InaccessibleCompanies,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNT_YTD,
      relativePath: 'accounting/account-ytd',
      component: YearToDateAccount,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNTING_ADD_TRANSACTION,
      relativePath: 'accounting/add-transaction',
      component: AddTransaction,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNTING_ALL_TRANSACTIONS,
      relativePath: 'accounting/all-transactions',
      component: AllTransactions,
    },
    {
      role: 'Customer',
      path: AppRoutes.VENDORS,
      relativePath: 'accounting/vendors',
      component: Vendors,
    },
    {
      role: 'Customer',
      path: AppRoutes.INVOICING,
      relativePath: 'invoicing',
      component: Invoicing,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNTING_UNKNOWN_TRANSACTIONS,
      relativePath: 'accounting/unknown-transactions',
      component: UnknownTransactions,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNTING_REPORTS,
      relativePath: 'accounting/reports',
      component: Reports,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNTING_BUSINESS_MILES,
      relativePath: 'accounting/business-mileage',
      component: BusinessMiles,
    },
    {
      role: 'Customer',
      path: AppRoutes.ACCOUNTING_BUSINESS_USE_OF_HOME,
      relativePath: 'accounting/business-use-of-home',
      component: BusinessUseOfHome,
    },
    {
      role: 'Customer',
      path: AppRoutes.PAYROLL,
      relativePath: 'payroll',
      component: PayrollMain,
    },
    {
      role: 'Customer',
      path: AppRoutes.PAYROLL_SETTINGS,
      relativePath: 'settings-and-requests',
      component: PayrollSettings,
    },
    {
      role: 'Customer',
      path: AppRoutes.PAYROLL_SETTINGS_REQUEST_CHANGE,
      relativePath: 'settings-and-requests/request-change',
      component: RequestPayrollChange,
    },
    {
      role: 'Customer',
      path: AppRoutes.PAYROLL_SETTINGS_REQUEST_PAYROLL,
      relativePath: 'settings-and-requests/request-payroll',
      component: RequestOffCyclePayroll,
    },
    {
      role: 'Customer',
      path: AppRoutes.PAYROLL_SETTINGS_REQUEST_PAYROLL_SUCCESS,
      relativePath: 'settings-and-requests/request-payroll-success',
      component: RequestOffCyclePayrollSuccess,
    },
    {
      role: 'Customer',
      path: AppRoutes.PAYROLL_ONBOARDING,
      relativePath: 'payroll-onboarding',
      exact: true,
      component: PayrollOnboarding,
    },
    {
      role: 'Customer',
      path: AppRoutes.TAXES,
      relativePath: 'taxes',
      component: TaxesDirectPayment,
    },
    {
      role: 'Customer',
      path: AppRoutes.OIL,
      relativePath: 'oil',
      component: CustomerOIL,
    },
    {
      role: 'Customer',
      path: AppRoutes.MY_COMPANY,
      relativePath: 'company',
      component: CompanyAccount,
    },
    {
      role: 'staff',
      path: AppRoutes.PAYROLL_DEDUCTIONS,
      relativePath: 'payroll-deductions',
      component: TaxNamesManagement,
    },
    {
      role: 'Customer',
      path: AppRoutes.BANK_ACCOUNTS,
      relativePath: 'accounting/bank-accounts',
      component: BankAccounts,
    },
    {
      role: 'Customer',
      path: AppRoutes.SETUP_PAGE,
      relativePath: 'setup-page',
      component: SetupPageView,
    },
    {
      role: 'Customer',
      path: AppRoutes.SQUARESPACE_DOMAIN_SEARCH,
      relativePath: 'domains/search',
      component: DomainSearchPage,
    },
    {
      role: 'Customer',
      path: AppRoutes.SQUARESPACE_DOMAIN_CHECKOUT,
      relativePath: 'domains/checkout',
      component: DomainCheckoutPage,
    },
    {
      role: 'Customer',
      path: AppRoutes.SQUARESPACE_DOMAIN_MANAGEMENT,
      relativePath: 'domains',
      component: DomainManagement,
    },
    {
      role: 'Customer',
      path: AppRoutes.SQUARESPACE_MANAGEMENT_LINK,
      relativePath: 'squarespace/link',
      component: SqspLinkPage,
    },
  ];

  return (
    <SideBarLayout isAdminSpoof={isAdminSpoof}>
      <Routes>
        <Route path="accounts/:id">
          <Route index element={<Company />} />
          <Route path=":tab" element={<Company />} />
        </Route>

        {routeList
          .filter((route: SidebarRoute) => route.role === userRole)
          .map((r: SidebarRoute) =>
            isAdminSpoof ? (
              <Route path={r.path} element={<r.component />} key={r.path} />
            ) : (
              <Route
                key={r.path}
                path={r.relativePath ?? r.path}
                element={<r.component />}
              />
            ),
          )}
        <Route
          path={`${isAdminSpoof ? '/dashboard' : ''}/onboarding/*`}
          element={
            isAdminSpoof ? <OnboardingRoutesSpoof /> : <OnboardingRoutes />
          }
        />
        <Route path="*" element={<RedirectToHomeOrLoginPage />} />
      </Routes>
    </SideBarLayout>
  );
};

export const SpoofSidebar = () => <GenerateSidebar isAdminSpoof />;
export const Sidebar = () => (
  <ChurnZeroSetupContact>
    <GenerateSidebar isAdminSpoof={false} />
  </ChurnZeroSetupContact>
);
