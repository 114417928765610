import { api } from 'services/axios';
import { BankAccount } from 'models/bankAccounts';
import { AxiosResponse } from 'axios';

export const createPlaidLinkToken = (accountId: string) =>
  api
    .post<{ for: string }, AxiosResponse<{ link_token: string }>>(
      `/plaid/link-token/${accountId}`,
      {
        for: 'hurdlr',
      },
    )
    .then((resp) => ({
      linkToken: resp.data.link_token,
    }));

export const createHurdlrLinkTokenForUpdate = (
  accountId: string,
  plaidItemId: string,
  enableAccountSelection: boolean = false,
) =>
  api
    .post<{}, AxiosResponse<{ link_token: string }>>(
      `/hurdlr/banks/${accountId}/plaid-link/${plaidItemId}`,
      {
        for: 'hurdlr',
      },
      {
        params: {
          enableAccountSelection,
        },
      },
    )
    .then((resp) => ({
      linkToken: resp.data.link_token,
    }));

export interface ConnectBankAccountParams {
  accountId: string;
  publicToken: string;
  institutionId: string;
  institutionName: string;
}

export const connectBankAccount = (
  params: ConnectBankAccountParams,
): Promise<void> =>
  api.post(`/hurdlr/banks/${params.accountId}`, {
    plaidPublicToken: params.publicToken,
    institutionId: params.institutionId,
    institutionName: params.institutionName,
  });

export const getBankAccounts = (accountId: string) =>
  api
    .get<BankAccount[]>(`/hurdlr/banks/${accountId}`)
    .then((resp) => resp.data);

export const removeBankAccount = (accountId: string, plaidItemId: string) =>
  api.delete(`/hurdlr/banks/${accountId}`, {
    params: {
      plaidItemId,
    },
  });

export const getHurdlrAccessToken = (accountId: string): Promise<string> =>
  api
    .get<{ accessToken: string }>(`/hurdlr/token/${accountId}`)
    .then((resp) => resp.data.accessToken);

export const refreshBankAccounts = (accountId: string): Promise<void> =>
  api.post(`/hurdlr/banks/${accountId}/refresh`);
