import { Routes } from 'fnRoutes';

export const hideTourButtonRoutes = [
  Routes.PROGRESS_TRACKER,
  Routes.ACCOUNT,
  Routes.MY_COMPANY,
  Routes.SETUP_PAGE,
  Routes.MY_SUBSCRIPTION,
  Routes.SQUARESPACE_DOMAIN_MANAGEMENT,
  Routes.SQUARESPACE_DOMAIN_SEARCH,
  Routes.SQUARESPACE_DOMAIN_CHECKOUT,
  Routes.SQUARESPACE_MANAGEMENT_LINK,
  Routes.PAYROLL_ONBOARDING,
];

export const shouldShowTourButton = (route: string) =>
  !hideTourButtonRoutes.includes(route);
