import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { UsersService } from 'services';
import {
  getCurrentUser,
  getUser,
  getUserIdentity,
  resetUserMFA,
  disableUserMFA,
  enableUserMFA,
  updateUser,
  UserInfo,
} from 'services/users';
import { useAuth0 } from '@auth0/auth0-react';
import { CONSTANTS } from 'constants/common';
import { useSpoof } from './useNavigate';

export const useCurrentUser = (
  queryOptions?: UseQueryOptions<{
    loginUser: UserInfo;
    currentUser: UserInfo;
  }>,
) => {
  const { isAuthenticated } = useAuth0();
  const { spoofID } = useSpoof();
  const { data, ...rest } = useQuery<
    { loginUser: UserInfo; currentUser: UserInfo },
    unknown
  >(
    'currentUser',
    async () => {
      const loginUser = await getCurrentUser();
      let currentUser = loginUser;
      if (spoofID) {
        currentUser = await getUser(spoofID);
      }
      return {
        loginUser,
        currentUser,
      };
    },
    {
      ...queryOptions,
      enabled: isAuthenticated,
    },
  );
  return {
    currentUser: data?.currentUser,
    loginUser: data?.loginUser,
    ...rest,
  };
};

export const useCurrentUserRole = (): {
  isAdmin: boolean;
  isLoginUserAdmin: boolean;
} => {
  const { spoofID } = useSpoof();
  const { currentUser, loginUser } = useCurrentUser();
  let isAdmin = currentUser?.role !== CONSTANTS.USER_ROLES.CUSTOMER;
  let isLoginUserAdmin = loginUser?.role !== CONSTANTS.USER_ROLES.CUSTOMER;
  if (spoofID) {
    isAdmin = false;
    isLoginUserAdmin = true;
  }
  return {
    isAdmin,
    isLoginUserAdmin,
  };
};

export const useUser = (
  id: string | undefined,
  options: UseQueryOptions<UserInfo> = {},
): Omit<UseQueryResult<UserInfo>, 'data'> & {
  user: UserInfo | undefined;
} => {
  const { data, ...rest } = useQuery<UserInfo, unknown>(
    ['user', id],
    () => getUser(id!),
    {
      enabled: !!id,
      ...options,
    },
  );
  return {
    user: data,
    ...rest,
  };
};

interface UpdateVariables {
  id?: string;
  data: any;
}
export const useUpdateUser = (
  queryProps?: Omit<
    UseMutationOptions<unknown, unknown, UpdateVariables>,
    'mutationFn'
  >,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ id, data }: { id?: string; data: any }) => updateUser(data, id),
    queryProps,
  );
  return {
    updateUser: mutate,
    updateUserAsync: mutateAsync,
    ...rest,
  };
};

interface UserIdentityVariable {
  id: string;
  ssn: string;
}
export const useUpdateUserIdentity = (
  queryProps?: Omit<
    UseMutationOptions<unknown, unknown, UserIdentityVariable>,
    'mutationFn'
  >,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ id, ssn }: { id: string; ssn: string }) =>
      UsersService.updateIdentityByUserId(id, { ssn }),
    queryProps,
  );

  return {
    updateUserIdentity: mutate,
    updateUserIdentityAsync: mutateAsync,
    ...rest,
  };
};

interface UserRoleVariable {
  id: string;
  role: string;
}

export const useUpdateUserRole = (
  queryProps?: Omit<
    UseMutationOptions<unknown, unknown, UserRoleVariable>,
    'mutationFn'
  >,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ id, role }: { id: string; role: string }) =>
      UsersService.updateUserRole(id, { role }),
    queryProps,
  );

  return {
    updateUserRole: mutate,
    updateUserRoleAsync: mutateAsync,
    ...rest,
  };
};

export interface UserIdentity {
  ssn: {
    first5: string;
    last4: string;
  };
}
export const useUserIdentityById = (
  id?: string,
  queryProps?: Omit<UseQueryOptions<UserIdentity>, 'queryKey' | 'queryFn'>,
) => {
  const { data, ...rest } = useQuery<UserIdentity>(
    ['user', 'identity', id],
    () => getUserIdentity(id),
    { enabled: !!id, ...queryProps },
  );
  return { userIdentity: data, ...rest };
};

export const useResetUserMFA = (userId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    resetUserMFA(userId),
  );
  return {
    resetUserMFA: mutate,
    resetUserMFAAsync: mutateAsync,
    ...rest,
  };
};

export const useDisableUserMFA = (userId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    disableUserMFA(userId),
  );
  return {
    disableUserMFA: mutate,
    disableUserMFAAsync: mutateAsync,
    ...rest,
  };
};

export const useEnableUserMFA = (userId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    enableUserMFA(userId),
  );
  return {
    enableUserMFA: mutate,
    enableUserMFAAsync: mutateAsync,
    ...rest,
  };
};
