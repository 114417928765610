import { CONFIG } from 'config';
import { MAX_DOCUMENT_SIZE_MB } from 'constants/documents';
import { ErrorCode, FileRejection } from 'react-dropzone';

export const displayWithFallback = (value: any, fallback: string = 'N/A') =>
  value || typeof value === 'number' ? value : fallback;

const maxFileNameSize = 1000;

export const nameLengthValidator = (file: File) => {
  if (file.name.length > maxFileNameSize) {
    return {
      code: 'name-too-large',
      message: `File Name must not be longer than ${maxFileNameSize} characters`,
    };
  }
  return null;
};

export const fileUploadErrorHandler = (
  fileRejections: FileRejection[],
  setError: (error: Error) => void,
) => {
  if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
    if (fileRejections[0].errors[0].code === ErrorCode.FileTooLarge) {
      setError(
        new Error(
          `The file is over ${MAX_DOCUMENT_SIZE_MB}MB, please retry ...`,
        ),
      );
    } else if (fileRejections[0].errors[0].code === ErrorCode.TooManyFiles) {
      setError(new Error('Too many files. Please upload only 1 file.'));
    } else if (fileRejections[0].errors[0].code === 'name-too-large') {
      setError(new Error(fileRejections[0].errors[0].message));
    } else {
      setError(
        new Error('Unsupported file type, upload an acceptable file format.'),
      );
    }
  }
};

// remove code and state query parameters from url search parameters
// this makes sure that browsers won't redirect to postAuth path again
export const removeAuthCode = (search: string) => {
  const searchParams = new URLSearchParams(search);
  searchParams.delete('code');
  searchParams.delete('state');
  return `?${searchParams.toString()}`;
};

export const getDefaultTaxYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear - 1;
};

export const getHubspotTicketsUrl = (ticketId: string) =>
  CONFIG.environment === 'production'
    ? `https://app.hubspot.com/contacts/6637631/record/0-5/${ticketId}`
    : `https://app.hubspot.com/contacts/22791610/record/0-5/${ticketId}`;
